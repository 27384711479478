<template>
  <div>
    <div class="mb-3 is-clearfix">
      <span>
        <button @click="copyToClipboard()" class="button is-rounded is-outlined" >
          <span class="pr-2">Copy Text</span>
          <ion-icon name="copy-outline"></ion-icon>
        </button>
      </span>
      <span class="select is-rounded is-pulled-right">
        <select v-model="languageSelected" @change="updater++" style="width: 220px">
          <option :value="l" v-for="(l, i) in languageAvailable" :key="i">
          {{ l.name }}
          </option>
        </select>
      </span>
    </div>
    <div class="box">
      <div v-if="popupShown" class="mask" @click="popupShown = false"></div>
      <div v-if="popupShown && errorByID" :style="popupComputed" class="box is-clipped is-primary popupWindow">
        <div v-if="errorByID.replacements.length > 0">
          <span v-for="(val, key) in errorByID.replacements" :key="key">
            <div v-if="key == 0">
              <div v-if="!val.value || val.value.length == 1">
                <a @click="quickfix(key)" class="button is-primary suggestionfix"> &#10003; </a>
              </div>
              <div v-else>
                <span class="is-size-4">
                  <span class="strike">{{ getErrorText }}</span> &nbsp; → &nbsp;
                </span>
                <a @click="quickfix(key)" class="button is-primary suggestionfix">{{ val.value }}</a>
                <div class="mt-5"/>
                </div>
              </div>
              <span v-else>
                <a @click="quickfix(key)" class="button is-small is-rounded is-light has-text-weight-bold mx-1 my-1">{{ val.value }}</a>
              </span>
          </span>
          <hr class="my-3">
         </div>
         <span class="has-text-gray-lighter is-size-6 has-text-weight-medium">{{ errorByID.message }}</span>
        </div>

        <div class="has-text-right" style="height: 10px; margin-right: -20px;">
          <button class="button is-white is-rounded actionbtn" @click="clearAll(false)" style="margin-top: -13px">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <Meditor id="meditor" ref="meditor" class="editor" :text="txt" :options='options' custom-tag='p' @edit='onLiveEdit'/>

        <div class="has-text-right" style="height: 20px; margin-right: -20px">
          <span v-if="isChecking">
            <button class="is-loading button is-white is-rounded actionbtn">
            </button>
          </span>
          <span v-else>
            <button @click="updater++" class="button is-white is-rounded actionbtn">
              <ion-icon v-if="lastCheckOk" name="reload-outline"></ion-icon>
              <ion-icon v-else name="warning-outline"></ion-icon>
            </button>
          </span>
        </div>

      </div>

      <div :class="[ confirmOpen ? 'is-active' : '', 'modal']">
        <div class="modal-background" @click="confirmOpen = false"></div>
        <div class="modal-content px-4">
          <div class="box">
            <p>Are you sure you want to start again?</p>
            <hr>
            <div class="has-text-right">
              <button class="button no" @click="confirmOpen = false">No</button>
              <button class="button is-danger yes" @click="clearAll(true)">Yes</button>
            </div>
          </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="confirmOpen = false"></button>
      </div>

      <textarea id="_hiddenCopyText_" style="position: absolute; left: -9999px; top: 0px;"></textarea>

    </div>
</template>

// https://github.com/yabwe/medium-editor#core-options
<script>
import axios from 'axios'
import _ from 'lodash'
import Meditor from 'vue2-medium-editor'

export default {
  components: { Meditor },
  computed: {
    popupComputed() {
      if (this.layout == 'desktop') {
        return {
          top: this.popupY+'px',
          left: this.popupX+'px',
        }
      }
      return {
        top: this.popupY+'px',
        left: '0px',
        width: '90%',
        margin: '20px',
      }
    },
    errorByID() {
      return _.find(this.errors, (e) => {return e.offset == this.popupID})
    },
    getErrorText() {
      let e = _.find(this.errors, (e) => {return e.offset == this.popupID})
      if (!e) return ''
      return this.innerText.slice(e.offset, e.offset+e.length)
    },
  },
  data() {
    return {
      api: 'aHR0cHM6Ly9hcGkuY29ycmVjdG1lLmFwcA==', // base64 encoded 'https://api.correctme.app', // proxyed with apache2 to lt
      txt:'I had the most great time meeting you, I could of talked with you all night! If you have time, I liked to have a 2rd meeting to getting to know you and to discuss this opportunity. We could either meet ourselves at my offices, next to the crappy statue of Harison Ford, on the twenty fifth floor, to have a conversation about the possibility of a partnership. If you are interested, please responds quickly via email or stop by the offices on 12,00 p.m. at thursday.',
      innerText: '',
      errors: {},
      options: {
        keyboardCommands: {
          commands: [
            {
              command: function () {
              },
              key: 'B',
              meta: true,
              shift: false,
              alt: false
            }, {
              command: function () {},
              key: 'I',
              meta: true,
              shift: false,
              alt: false
            }, {
              command: function () {},
              key: 'U',
              meta: true,
              shift: false,
              alt: false
            }, {
              command: () => {
                let mapi = this.$refs.meditor.api
                if (!mapi) return
                let s = mapi.exportSelection()
                let all = this.textContent(mapi.getContent())
                let selection = all.slice(s.start, s.end)
                this.copyToClipboard(selection)
                mapi.importSelection(s)
              },
              key: 'C', // ctrl+c copy to clipboard text only
              meta: true,
              shift: false,
              alt: false
            }
          ]
        },
        placeholder: {
          text: 'Enter or paste your text',
          hideOnClick: false,
        },
        spellcheck: false,
        toolbar: false,
      },
      typing: false,
      confirmOpen: false,
      popupShown: false,
      popupX: 0,
      popupY: 0,
      popupID: 0,
      languageSelected: {name:' Auto detect language', code:'auto', longCode:'en-US'},
      languageActive: {name:'English (US)', code:'en', longCode:'en-US'},
      languageAvailable: {0: {name:' Auto detect language', code:'auto', longCode:'en-US'}},
      updater: 1,
      isChecking: false,
      lastCheckOk: true,
    }
  },
  watch: {
    updater: function () {
      let t = this.innerText
      this.innerText = ''
      this.checkText(t)
    },
  },
  mounted() {
    this.getLanguages()
    this.checkText(this.txt)
  },
  methods: {
    onLiveEdit(e) {
      this.typing = true
      this.onLiveEditDebounce(e.event.target.innerText)
    },
    onLiveEditDebounce: _.debounce(function(text) {
      this.typing = false
      this.checkText(text)
      }, 2000),
    getLanguages() {

      //axios.get(atob(this.api)+'/languages')
      axios.get('https://languagetool.org/api/v2/languages')
        .then(res => {
          this.languageAvailable = _.merge({x: {name:' Auto detect language', code:'auto', longCode:'en-US'}}, res.data)
          this.languageAvailable = _.sortBy(this.languageAvailable, (l) => {return l.name})
        })

    },
    checkText(input) {

      let mapi = this.$refs.meditor.api

      // skip if we're looking at another error or the content is not changed
      if (this.innerText == this.textContent(input)) {
        return
      }
      if (this.popupShown || this.isChecking) {
        return
      }

      let final = ''
      let wrap_start = '<i class="err">'
      let wrap_end = '</i>'

      this.isChecking = true
      let bodyFormData = new FormData()
      let language = this.languageSelected.code == 'auto' ? this.languageActive.longCode : this.languageSelected.longCode
      bodyFormData.append('language', language);
      bodyFormData.append('text', input);
      axios({
        method: 'post',
        //url: atob(this.api)+'/check',
        url: 'https://languagetool.org/api/v2/check',
        //data: bodyFormData,
        data: 'language='+language+'&text='+encodeURIComponent(input),
        headers: {'Content-Type': 'multipart/form-data' }
      })
        .then(res => {
          this.lastCheckOk = true

          if (this.typing) {
            return
          }

          let ffd = 0
          let m = res.data.matches
          m = _.orderBy(m, ['offset'],['asc'])
          final = input
          this.errors = m
          _.forEach(m, e => {
            let ff = ffd + e.offset
            wrap_start = this.getClassByErrorType(e)
            final = [final.slice(0, ff), wrap_start, final.slice(ff, ff+e.length), wrap_end, final.slice(ff+e.length)].join('')
            ffd += (wrap_start + wrap_end).length
          })

          // nl2br
          let fixed = this.nl2br(final)

          if (this.languageSelected.code == 'auto' && res.data.language.detectedLanguage.code != this.languageActive.longCode) {
            this.languageActive = _.find(this.languageAvailable, (l) => l.longCode == res.data.language.detectedLanguage.code)
            this.innerText = this.textContent(final)
            this.updater++
            return
          }

          this.txt = '<div>'+fixed+'</div>'

          // remember cursor position
          let caret = mapi.exportSelection()
          this.txt = fixed
          this.innerText = this.textContent(this.txt)

          this.$nextTick(() => {
            // restore cursor position
            mapi.importSelection(caret)
            let errs = document.querySelectorAll('.err')
            _.forEach(errs, (e) => {
              e.addEventListener('click', this.handleErrorClick)
            })
          })
        })
        .catch(() => {
          this.lastCheckOk = false
        })
        .then(() => {
          // always run
          this.isChecking = false
        })
    },
    handleErrorClick(e) {
      e.stopPropagation()
      let errwidth = 400
      let x = e.layerX-10
      let y = e.layerY+20
      if (x+errwidth+120 > window.innerWidth) {
        // too far right
        x = x-errwidth+120
      }
      if (window.innerWidth < 800) {
        // mobile
        x = 10
        this.layout = 'mobile'
      } else {
        this.layout = 'desktop'
      }

      this.popupShown = true
      this.popupX = x
      this.popupY = y
      this.popupID = e.srcElement.id
    },
    getClassByErrorType(e) {
      // https://languagetool.org/development/api/org/languagetool/rules/Categories.html
      switch(e.rule.category.id) {
        case 'TYPOS':
          return '<i id="'+e.offset+'" class="err typo">'
        case 'TYPOGRAPHY':
          return '<i id="'+e.offset+'" class="err typography">'
        case 'CASING':
        case 'STYLE':
          return '<i id="'+e.offset+'" class="err casing">'
        default:
          return '<i id="'+e.offset+'" class="err">'
      }
    },
    quickfix(idx) {
      let err = _.find(this.errors, (e) => {return e.offset == this.popupID})
      let errel = document.getElementById(this.popupID)
      var replacement = document.createTextNode(err.replacements[idx].value)
      errel.parentNode.replaceChild(replacement, errel)

      // remove this error
      this.errors = _.reject(this.errors, (e) => {return e.offset == this.popupID})

      // close popup
      this.popupShown = false

      // re-check
      let mapi = this.$refs.meditor.api
      mapi.checkContentChanged()
    },
    textContent(raw) {
      let span = document.createElement('span')
      span.innerHTML = raw

      // br2nl
      span.innerHTML = this.br2nl(span.innerHTML)

      return span.textContent || span.innerText
    },
    br2nl(input) {
      return input.replace(/<br\s*[/]?>/g, "\n")
    },
    nl2br(input) {
      return input.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    clearAll(confirm) {
      if (!confirm) {
        this.confirmOpen = true
        return
      }
      this.confirmOpen = false
      let mapi = this.$refs.meditor.api
      mapi.setContent('')
      this.updater++
    },
    copyToClipboard(value) {
      if (!value) {
        let mapi = this.$refs.meditor.api
        value = this.textContent(mapi.getContent())
      }
      //let value = this.textContent(this.innerText)
      let target = document.getElementById("_hiddenCopyText_")
      target.textContent = value
      // select the content
      let currentFocus = document.activeElement
      target.focus()
      target.setSelectionRange(0, target.value.length)
      // copy the selection
      document.execCommand("copy")

      // restore original focus
      if (currentFocus && typeof currentFocus.focus === "function") {
          currentFocus.focus()
      }
      // clear temporary content
      target.textContent = ""

      // blink
      document.getElementById('meditor').style.opacity = .2
      setTimeout(() => { document.getElementById('meditor').style.opacity = 1}, 100);
    },
    async pasteAll() {
      const text = await navigator.clipboard.readText();
      let mapi = this.$refs.meditor.api
      mapi.setContent(text)
      this.updater++
    },
  }
}
</script>

<style>
.editor {
  outline: none;
}
.err {
  min-width: 2px;
  font-style: normal;
  border-bottom: 3px solid #FF99AB;
  cursor: pointer;
}
.typo {
  border-bottom-color: red;
}
.typography {
  border-bottom-color: green;
}
.casing {
  border-bottom-color: blue;
}
.popupWindow {
  width: 400px;
  position: absolute;
  z-index: 5000;
  box-shadow: 0 1.3em 2em 0.825em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
}
.suggestionfix {
  font-weight: bold;
}
.strike {
  text-decoration: line-through
}
#meditor {
  min-height: 200px;
  padding: 0 10px;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1000;
}
.actionbtn {
  padding: 0px!important;
  width: 30px;
  height: 30px;
}
.button {
  margin-right: .5rem!important;
  margin-bottom: .5rem!important;
}
</style>
