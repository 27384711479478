import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// ignore ion icon
Vue.config.ignoredElements = [/^ion-/]

new Vue({
  render: h => h(App),
}).$mount('#app')
